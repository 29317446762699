<template>
    <div class="sidebar-wrapper" data-simplebar="true">
        <div class="sidebar-header">
            <div>
                <img src="../../../../public/core/assets/logo.png" class="logo-icon" alt="logo icon" />
            </div>
            <div>
                <h4 class="logo-text" style="font-size: 18px;">Hyman Life</h4>
            </div>
            <div class="toggle-icon ms-auto">
                <i class="bx bx-arrow-to-left"></i>
            </div>
        </div>
        <ul class="metismenu" id="menu">
            <li>
                <a href="javascript:void(0)" @click="$router.push('/home')">
                    <div class="parent-icon">
                        <i class='bx bxs-dashboard'></i>
                    </div>
                    <div class="menu-title">Dashboard</div>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="$router.push('/contact')">
                    <div class="parent-icon">
                        <i class='bx bxs-message'></i>
                    </div>
                    <div class="menu-title">Contact</div>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="$router.push('/retailers')">
                    <div class="parent-icon">
                        <i class='bx bxs-store'></i>
                    </div>
                    <div class="menu-title">Retailers</div>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="$router.push('/product-list')">
                    <div class="parent-icon">
                        <i class='bx bxs-package'></i>
                    </div>
                    <div class="menu-title">Products</div>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="$router.push('/strains')">
                    <div class="parent-icon">
                        <i class='bx bxs-cookie'></i>
                    </div>
                    <div class="menu-title">Strains</div>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="$router.push('/projects')">
                    <div class="parent-icon">
                        <i class='bx bxs-layer'></i>
                    </div>
                    <div class="menu-title">Projects</div>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="$router.push('/newsletter')">
                    <div class="parent-icon">
                      <i class='bx bxs-news'></i>
                    </div>
                    <div class="menu-title">Newsletter</div>
                </a>
            </li>
            <li>
                <a href="#" class="has-arrow">
                    <div class="parent-icon">
                        <i class='bx bxs-user'></i>
                    </div>
                    <div class="menu-title">User</div>
                </a>
                <ul>
                    <li>
                        <a href="javascript:void(0)" @click="$router.push('/admins')">
                            <i class="bx bx-right-arrow-alt"></i>Admins
                        </a>
                    </li>
                    <!-- <li>
                        <a href="javascript:void(0)" @click="$router.push('/customers')">
                            <i class="bx bx-right-arrow-alt"></i>Customers
                        </a>
                    </li> -->
                </ul>
            </li>
            <li>
                <a class="has-arrow" href="javascript:void(0)">
                    <div class="parent-icon">
                        <i class="fa-solid fa-blog"></i>
                    </div>
                    <div class="menu-title">Blog</div>
                </a>
                <ul>
                    <li>
                        <a href="javascript:void(0);" @click="$router.push('/blog-post')">
                            <i class="bx bx-right-arrow-alt"></i>Blog Post
                        </a>
                    </li>
                </ul>
            </li>
            <li>
                <a href="#" class="has-arrow">
                    <div class="parent-icon">
                        <i class="fadeIn animated bx bxs-briefcase-alt-2"></i>
                    </div>
                    <div class="menu-title">Career</div>
                </a>
                <ul>
                    <li>
                        <a href="javascript:void(0)" @click="$router.push('/career')">
                            <i class="bx bx-right-arrow-alt"></i>Career List
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" @click="$router.push('/career-applicant')">
                            <i class="bx bx-right-arrow-alt"></i>Career Applicant
                        </a>
                    </li>
                </ul>
            </li>
            <li>
                <a class="has-arrow" href="javascript:void(0)">
                    <div class="parent-icon">
                        <i class='bx bxs-cog'></i>
                    </div>
                    <div class="menu-title">CMS Settings</div>
                </a>
                <ul>
                    <li>
                        <a class="has-arrow" href="javascript:void(0)">
                            <div class="parent-icon">
                                <i class='bx bx-home-alt'></i>
                            </div>
                            Home
                        </a>
                        <ul>
                            <li>
                                <a href="/hero-section" @click.prevent="$router.push('/home-page-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Home Page CMS
                                </a>
                            </li>
                            <li>
                                <a href="/hero-section" @click.prevent="$router.push('/hero-section-slider')">
                                    <i class="bx bx-right-arrow-alt"></i>Hero Section Slider
                                </a>
                            </li>
                            <li>
                                <a href="/hero-section" @click.prevent="$router.push('/hero-section-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Hero Section CMS
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a class="has-arrow" href="javascript:void(0)">
                            <div class="parent-icon">
                                <i class='bx bx-copy-alt'></i>
                            </div>
                            Header Footer
                        </a>
                        <ul>
                            <li>
                                <a href="/header-banner-cms" @click.prevent="$router.push('/header-footer-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Header Footer CMS
                                </a>
                            </li>
                            <!-- <li>
                                <a href="/footer-cms" @click.prevent="$router.push('/footer-banner-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Footer Banner CMS
                                </a>
                            </li> -->
                        </ul>
                    </li>
                    <li>
                        <a class="has-arrow" href="javascript:void(0)">
                            <div class="parent-icon">
                                <i class='bx bx-error-circle'></i>
                            </div>
                            About Us
                        </a>
                        <ul>
                            <li>
                                <a href="/hero-section" @click.prevent="$router.push('/about-us-section-one-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Section One CMS
                                </a>
                            </li>
                            <li>
                                <a href="/hero-section" @click.prevent="$router.push('/about-us-section-two-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Section Two CMS
                                </a>
                            </li>
                            <li>
                                <a href="/hero-section" @click.prevent="$router.push('/about-us-section-three-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Section Three CMS
                                </a>
                            </li>
                            <li>
                                <a href="/hero-section" @click.prevent="$router.push('/about-us-section-four-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Section Four CMS
                                </a>
                            </li>
                            <li>
                                <a href="/hero-section" @click.prevent="$router.push('/about-us-section-five-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Section Five CMS
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a class="has-arrow" href="javascript:void(0)">
                            <div class="parent-icon">
                                <i class="fa-solid fa-question"></i>
                            </div>
                            FAQ
                        </a>
                        <ul>
                            <li>
                                <a href="/footer-cms" @click.prevent="$router.push('/faq')">
                                    <i class="bx bx-right-arrow-alt"></i>Q & A
                                </a>
                            </li>
                            <li>
                                <a href="/hero-section" @click.prevent="$router.push('/about-us-faq-section-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>FAQ Section CMS
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a class="has-arrow" href="javascript:void(0)">
                            <div class="parent-icon">
                                <i class='bx bx-briefcase-alt'></i>
                            </div>
                            Career
                        </a>
                        <ul>
                            <li>
                                <a href="/footer-cms" @click.prevent="$router.push('/career-list-page-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Career Page CMS
                                </a>
                            </li>
                            <li>
                                <a href="/footer-cms" @click.prevent="$router.push('/apply-career-modal-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Career Modal CMS
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="/strain-page-cms" @click.prevent="$router.push('/strain-page-cms')">
                            <i class="bx bx-right-arrow-alt"></i>Strain Page CMS
                        </a>
                    </li>
                    <li>
                        <a href="/strain-page-cms" @click.prevent="$router.push('/project-page-cms')">
                            <i class="bx bx-right-arrow-alt"></i>Project Page CMS
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" @click="$router.push('/newsletter-banner-cms')">
                            <i class="bx bx-right-arrow-alt"></i>Newsletter Banner CMS
                        </a>
                    </li>
                    <li>
                        <a href="/contact-us-cms" @click.prevent="$router.push('/contact-us-cms')">
                            <i class="bx bx-right-arrow-alt"></i>Contact Us Page CMS
                        </a>
                    </li>
                    <li>
                        <a href="/contact-us-cms" @click.prevent="$router.push('/social-links')">
                            <i class="bx bx-right-arrow-alt"></i>Social Links
                        </a>
                    </li>
                    <li>
                        <a href="/privacy-page-cms" @click.prevent="$router.push('/privacy-page-cms')">
                            <i class="bx bx-right-arrow-alt"></i>Privacy Policy Page CMS
                        </a>
                    </li>
                    <li>
                        <a href="/refund-page-cms" @click.prevent="$router.push('/terms-page-cms')">
                            <i class="bx bx-right-arrow-alt"></i>Terms & Condition Page CMS
                        </a>
                    </li>
                    <li>
                        <a href="/age-verification-modal-cms" @click.prevent="$router.push('/age-verification-modal-cms')">
                            <i class="bx bx-right-arrow-alt"></i>Age Verification Modal CMS
                        </a>
                    </li>

                </ul>
            </li>
        </ul>
    </div>
</template>