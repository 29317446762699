// import { authHeader } from '../../../auth';

export default {
    name: "CareerApplicant",
    data() {
        return {
            dataList: [],
            setURL: this.$serverURL + this.$api.career.careerApplicantURL,
            coverLetter: ""
        }
    },
    async created() {
        document.title = "Hyman Life - Career Applicant";
        this.dataList = await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        async updateModalValue(coverLetter) {
            this.coverLetter = coverLetter;
        },
        deleteContent: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let token = localStorage.getItem('token');
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.setURL + '/' + id,
                            headers: {
                                "Authorization": "Bearer " + token
                            }
                        }
                        await this.deleteData(config);
                        this.dataList = await this.getDataList(this.setURL);
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    }
                }
            })

        }
    }
}