import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            id: this.$route.params.id,
            siteUrl: this.$serverURL + this.$api.product.list,
            data: [],
            singleProduct: {},
            name: '',
            project: {},
            strain: {},
            featuredImage: "",
            previewFeaturedImage: null,
            projectList: [],
            strainList: [],
            aromaSpecificationArray: {},
            aromaSpecificationValue: [],
            aromaSpecificationKey: [],
            aromaCounter: 0,
            experienceSpecificationArray: {},
            experienceSpecificationValue: [],
            experienceSpecificationKey: [],
            experienceCounter: 0,
            flavourSpecificationArray: {},
            flavourSpecificationValue: [],
            flavourSpecificationKey: [],
            flavourCounter: 0,
            availableInSpecificationArray: {},
            availableInSpecificationValue: [],
            availableInSpecificationKey: [],
            availableInCounter: 0,
            strainType: '',
            isNewestArrival: 0,
        }

    },
    async mounted() {
        await this.getProjectList();
        await this.getStrainList();
        await this.getProductDetails();
    },
    methods: {
        getProductDetails: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.siteUrl + '/' + this.id,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        if (response.data?.data?.length) {
                            this.singleProduct = response.data.data[0];
                            if (this.singleProduct.name) {
                                this.name = this.singleProduct.name;
                            }

                            if (this.singleProduct.isNewestArrival) {
                                this.isNewestArrival = this.singleProduct.isNewestArrival ? true : false;
                            }

                            if (this.singleProduct.featuredImage) {
                                this.featuredImage = this.singleProduct.featuredImage;
                                this.previewFeaturedImage = this.featuredImage;
                            }
                            let index = 0;
                            for (const [key, value] of Object.entries(JSON.parse(this.singleProduct.aroma))) {
                                this.aromaSpecificationKey[index] = key;
                                this.aromaSpecificationValue[index] = value;
                                this.aromaSpecificationArray[this.aromaSpecificationKey[index]] = this.aromaSpecificationValue[index];
                                index++;
                                this.aromaCounter += 1;
                            }
                            index = 0;
                            for (const [key, value] of Object.entries(JSON.parse(this.singleProduct.experience))) {
                                this.experienceSpecificationKey[index] = key;
                                this.experienceSpecificationValue[index] = value;
                                this.experienceSpecificationArray[this.experienceSpecificationKey[index]] = this.experienceSpecificationValue[index];
                                index++;
                                this.experienceCounter += 1;
                            }
                            index = 0;
                            for (const [key, value] of Object.entries(JSON.parse(this.singleProduct.flavour))) {
                                this.flavourSpecificationKey[index] = key;
                                this.flavourSpecificationValue[index] = value;
                                this.flavourSpecificationArray[this.flavourSpecificationKey[index]] = this.flavourSpecificationValue[index];
                                index++;
                                this.flavourCounter += 1;
                            }

                            index = 0;
                            for (const [key, value] of Object.entries(JSON.parse(this.singleProduct.availableIn))) {
                                this.availableInSpecificationKey[index] = key;
                                this.availableInSpecificationValue[index] = value;
                                this.availableInSpecificationArray[this.availableInSpecificationKey[index]] = this.availableInSpecificationValue[index];
                                index++;
                                this.availableInCounter += 1;
                            }


                            this.project = {
                                id: this.singleProduct.projectId,
                                name: this.singleProduct.projectName,
                            }


                            this.strain = {
                                id: this.singleProduct.strainId,
                                name: this.singleProduct.strainName,
                            }

                            this.strainType = this.singleProduct.strainType;
                        }
                    }
                }).catch(error => {
                    console.log(error.response.data.message);
                });

            } catch (error) {
                console.log(error);
            }
        },
        uploadFeaturedImage() {
            this.featuredImage = event.target.files[0];
            let input = this.$refs.featuredImageInput
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewFeaturedImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        getProjectList: async function () {
            let url = this.$serverURL + this.$api.project.projectsAPI;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.projectList = response.data.data;
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getStrainList: async function () {
            let url = this.$serverURL + this.$api.strains.strainsAPI;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.strainList = response.data.data;
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        arrayValidation: function () {

            this.aromaSpecificationArray = {};
            for (let i = 0; i < this.aromaCounter; i++) {
                this.aromaSpecificationArray[i] = this.aromaSpecificationValue[i];
            }

            if (!this.finalSpecification('aromaSpecificationValue', 'aromaCounter')) {
                return;
            }

            this.experienceSpecificationArray = {};
            for (let i = 0; i < this.experienceCounter; i++) {
                this.experienceSpecificationArray[i] = this.experienceSpecificationValue[i];
            }

            if (!this.finalSpecification('experienceSpecificationValue', 'experienceCounter')) {
                return;
            }

            this.flavourSpecificationArray = {};
            for (let i = 0; i < this.flavourCounter; i++) {
                this.flavourSpecificationArray[i] = this.flavourSpecificationValue[i];
            }

            if (!this.finalSpecification('flavourSpecificationValue', 'flavourCounter')) {
                return;
            }

            this.availableInSpecificationArray = {};
            for (let i = 0; i < this.availableInCounter; i++) {
                this.availableInSpecificationArray[i] = this.availableInSpecificationValue[i];
            }

            if (!this.finalSpecification('availableInSpecificationValue', 'availableInCounter')) {
                return;
            }

            return true;
        },
        checkValidation: function () {
            if (!this.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product name!"
                });
                return false;
            } else if (!this.featuredImage) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter featured image!"
                });
                return false;
            } else if (!this.arrayValidation()) {
                return false;
            } else if (!(Object.keys(this.project).length)) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter project information!"
                });
                return false;
            } else if (!(Object.keys(this.strain).length)) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter strain information!"
                });
                return false;
            } else if (!this.strainType) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter strain type!"
                });
                return false;
            } else {
                return true;
            }

        },
        addSpecification(specificationValue, arrayCounter) {
            // specificationValue
            let index = this[arrayCounter] - 1;
            if (!this[specificationValue][index]) {
                let specificationName = arrayCounter.split('Counter')[0];
                if (specificationName === 'availableIn') specificationName = 'Available In';
                this.$swal.fire({
                    icon: "error",
                    text: `Please enter a ${specificationName}!`
                });
                return;
            }
            this[arrayCounter] = this[arrayCounter] + 1;
        },
        finalSpecification(array, arrayCounter) {
            // specificationValue
            let index = this[arrayCounter] - 1;
            if (!this[array][index]) {
                let specificationName = arrayCounter.split('Counter')[0];
                if (specificationName === 'availableIn') specificationName = 'Available In';
                this.$swal.fire({
                    icon: "error",
                    text: `Please enter ${specificationName}!`
                });
                return false;
            }
            return true;
        },
        deleteSpecificationRow(specifictionArray, specifictionValue, arrayCounter, index) {
            // specificationArray specificationValue specificationValue
            delete this[specifictionArray][this[specifictionValue][index]];
            this[specifictionValue].splice(index, 1);
            this[arrayCounter] = this[arrayCounter] - 1;
        },
        updateProduct: async function () {
            this.$swal.showLoading();
            if (this.checkValidation()) {
                let formData = new FormData();
                formData.append("name", this.name);
                formData.append("projectId", this.project.id);
                formData.append("projectName", this.project.name);
                formData.append("featuredImage", this.featuredImage);
                formData.append("strainId", this.strain.id);
                formData.append("strainName", this.strain.name);
                formData.append("strainType", this.strainType);
                formData.append("isNewestArrival", this.isNewestArrival ? 1 : 0);
                formData.append("aroma", JSON.stringify(this.aromaSpecificationArray));
                formData.append("experience", JSON.stringify(this.experienceSpecificationArray));
                formData.append("flavour", JSON.stringify(this.flavourSpecificationArray));
                formData.append("availableIn", JSON.stringify(this.availableInSpecificationArray));
                let config = {
                    method: "PATCH",
                    data: formData,
                    url: this.siteUrl + '/' + this.id,
                    headers: {
                        "Authorization": authHeader()
                    }
                }
                await this.$axios(config).then(async (response) => {
                    this.$swal.hideLoading();
                    if (response.status == 200) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.$router.push("/product-list");
                    }
                    else {

                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again." + error
                    });
                });
            }
        },
    }
}