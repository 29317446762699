import { authHeader } from "../../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    name: "Hero Section Slider",
    data() {
        return {
            editor: ClassicEditor,
            setURL: this.$serverURL + this.$api.home.heroSectionAPI,
            productUrl: this.$serverURL + this.$api.product.list,
            productList: [],
            product: {},
            singleData: {},
            buttonText: "",
        }
    },
    async created() {
        document.title = "Hyman Life - Home Hero Section Slider";
        await this.getDataList(this.setURL);
        await this.getProductList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        getProductList: async function () {
            let config = {
                method: "GET",
                url: this.productUrl,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.productList = response.data.data;
                    console.log(this.productList);
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        newHeroSection: function () {
            this.product = {};
            this.buttonText = '';
        },
        addHeroSection: async function () {
            console.log(this.product);
            if (!(Object.keys(this.product).length)) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product!"
                });
            } else if (!this.buttonText) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter buttonText!"
                });
            } 
            else {
                const data = {
                    productId: this.product.id,
                    productName: this.product.name,
                    buttonText: this.buttonText,
                }

                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader(),
                    }
                };
                await this.postDataToBackend(config);
                this.product = {};
                this.buttonText = '';
                await this.getDataList(this.setURL);

                window.$("#addModal").modal('hide');
            }
        },
        editHeroSection: async function (data) {
            this.singleData = data;
            this.product = {
                id: data.productId,
                name: data.productName,
            }
        },
        updateHeroSection: async function () {
            if (!(Object.keys(this.product).length)) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product!"
                });
            } else if (!this.singleData.buttonText) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter buttonText!"
                });
            } else {
                const data = {
                    productId: this.product.id,
                    productName: this.product.name,
                    buttonText: this.singleData.buttonText,
                }
                let config = {
                    method: "PATCH",
                    url: `${this.setURL}${this.singleData.id}` ,
                    data: data,
                    headers: {
                        "Authorization": authHeader(),
                    }
                };
                await this.updateDataToBackend(config);
                this.singleData = {};
                this.product = {};
                this.buttonText = '';
                await this.getDataList(this.setURL);
                window.$("#editModal").modal('hide');
            }
        },
        deleteHeroSection: function (id) {
            this.$swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then(async (result) => {
                if (result.isConfirmed) {
                    if (result.isConfirmed) {
                        try {
                            let config = {
                                method: 'DELETE',
                                url: `${this.setURL}${id}`,
                                headers: {
                                    "Authorization": authHeader(),
                                }
                            }
                            await this.deleteDataToBackend(config);
                            await this.getDataList(this.setURL);

                        } catch (error) {
                            this.$swal.fire({
                                icon: "error",
                                text: "Something went wrong, please try agian later." + error
                            });
                        }
                    }
                }
            })

        }
    }
}