import { authHeader } from "../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
            setURL: this.$serverURL + this.$api.project.projectsAPI,
            singleAPI: this.$serverURL + this.$api.project.singleProjectAPI,
            title: "",
            image: "",
            previewImage: "",
            videoThumbnail: "",
            previewVideoThumbnail: "",
            description: "",
            heading: "",
            buttonText: "",
            uploadType: "image",
            video: "",
            preview: {
                video: ""
            },
            allowedVideoType: ['mp4', 'avi', 'mkv'],
        }

    },
    async mounted() {
    },
    methods: {
        uploadProjectImage: function (event) {
            this.image = event.target.files[0];
            let input = this.$refs.image;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadVideoThumbnail: function (event) {
            this.videoThumbnail = event.target.files[0];
            let input = this.$refs.videoThumbnail;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewVideoThumbnail = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadVideo(videoKey, previewVideoKey, ref, event) {
            const filetype = event.target.files[0].type.split('/').pop();
            if(!this.allowedVideoType.includes(filetype)) {
                this.$swal.fire({
                    icon: "error",
                    text: "Video should be in avi/mkv/mp4 format!"
                })
                this.preview[previewVideoKey] = '';
                this[videoKey] = '';
                return false;
            }
            this.video = '';
            this.preview.video = '';
            this[videoKey] = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.preview[previewVideoKey] = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        validate() {
            if (this.uploadType === 'image' && !this.heading) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add project heading."
                })
                return false;
            } else if (!this.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add project title."
                })
                return false;
            } else if (this.uploadType === 'image' && !this.buttonText) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add button text."
                })
                return false;
            } else if (this.uploadType === 'image' && !this.image) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add project image."
                })
                return false;
            } else if (this.uploadType === 'video' && !this.videoThumbnail) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add video thumbnail."
                })
                return false;
            } else if (this.uploadType === 'video' && !this.video) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add video."
                })
                return false;
            } else if (!this.description) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add project description."
                })
                return false;
            }
            return true;
        },
        addProject: async function () {
            if (this.validate()) {
                try {
                    this.$swal.showLoading();
                    let formData = new FormData();
                    if (this.uploadType === 'image') {
                        formData.append('heading', this.heading);
                        formData.append('buttonText', this.buttonText);
                        formData.append('image', this.image);
                    } else if (this.uploadType === 'video') {
                        formData.append('videoThumbnail', this.videoThumbnail);
                        formData.append('image', this.video);
                    }
                    formData.append('title', this.title)
                    formData.append('description', this.description);
                    let config = {
                        method: 'POST',
                        url: this.setURL,
                        data: formData,
                        headers: {
                            "Authorization": authHeader(),
                            "Content-Type": "multipart/form-data",
                        }
                    };
                    await this.postDataToBackend(config);
                    this.$router.push("/projects");
                } catch (error) {
                    this.$swal.hideLoading();
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! " + error
                    });
                }
            }

        },
    }
}