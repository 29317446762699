import { authHeader } from "../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {      
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
            setURL: this.$serverURL + this.$api.strains.singleStrainAPI,
            id: this.$route.params.id,
            previewImage: "",
            singleStrain: {},
        }

    },
    async mounted() {
        await this.getStrainData();
    },
    methods: {
        uploadStrainImage: function (event) {
            this.singleStrain.image = event.target.files[0];
            let input = this.$refs.image;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        validate() {
            if (!this.singleStrain.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add strain title."
                })
                return false;
            }  else if (!this.singleStrain.logo) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add strain logo."
                })
                return false;
            } 
            return true;
        },
        getStrainData: async function () {
            let config = {
                method: "GET",
                url: this.setURL + this.id,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.singleStrain = await response.data.data[0];
                }
                else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        updateStrain: async function () {
            if (this.validate()) {
                try {
                    let formData = new FormData();
                    formData.append('name', this.singleStrain.name);
                    formData.append('logo', this.singleStrain.logo);
                    let config = {
                        method: 'PATCH',
                        url: this.setURL + this.singleStrain.id,
                        data: formData,
                        headers: {
                            "Authorization": authHeader(),
                            "Content-Type": "multipart/form-data",
                        }
                    };
                    await this.updateDataToBackend(config);
                    this.$router.push("/strains");
                } catch (error) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! " + error
                    });
                }
            }

        }
    },
}