import { authHeader } from "../../../auth";
export default {
    data() {
        return {
            setURL: this.$serverURL + this.$api.strains.strainsAPI,
            singleAPI: this.$serverURL + this.$api.strains.singleStrainAPI,
            name: "",
            logo: "",
            previewLogo: "",    
        }

    },
    async mounted() {
    },
    methods: {
        uploadStrainLogo: function (event) {
            this.logo = event.target.files[0];
            let input = this.$refs.logo;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewLogo = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        validate() {
            if (!this.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add strain title."
                })
                return false;
            } else if (!this.logo) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add strain image."
                })
                return false;
            } 
            return true;
        },
        addStrain: async function () {
            if (this.validate()) {
                try {
                    this.$swal.showLoading();
                    let formData = new FormData();
                    formData.append('name', this.name)
                    formData.append('logo', this.logo);
                    let config = {
                        method: 'POST',
                        url: this.setURL,
                        data: formData,
                        headers: {
                            "Authorization": authHeader(),
                            "Content-Type": "multipart/form-data",
                        }
                    };
                    await this.postDataToBackend(config);
                    this.$router.push("/strains");
                } catch (error) {
                    this.$swal.hideLoading();
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! " + error
                    });
                }
            }

        },
    }
}