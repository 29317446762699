<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Add Product
            </li>
          </ol>
        </nav>
        <div class="ms-auto"></div>
      </div>
      <hr />
      <form>
        <div class="card">
          <div class="card-body p-4">
            <h5 class="card-title">Add New Product</h5>
            <hr />
            <div class="form-body mt-4">

              <div class="row">
                <div class="col-lg-8">
                  <div class="border border-3 p-4 rounded mb-4">
                    <div class="mb-3">
                      <label for="inputProductTitle" class="form-label">Product Name:</label>
                      <input type="text" class="form-control" id="inputProductTitle" v-model="name"
                        placeholder="Enter product name" />
                    </div>
                  </div>
                  <div class="border border-3 p-4 rounded mb-3">
                    <label for="exampleInputEmail1">Featured Image:
                      <strong>[Preferred Image Size: 400X400, Max Upload Limit:
                        1MB]</strong></label>
                    <input class="form-control mb-2" type="file" name="featured_image" id="featuredImage"
                      ref="featuredImageInput" aria-describedby="imageHelp" accept="image/*"
                      @change="uploadFeaturedImage()" />
                    <img v-if="previewFeaturedImage" :src="previewFeaturedImage" height="90" />
                    <div id="display_image"></div>
                  </div>

                  <!-- aroma -->
                  <div class="border border-3 p-4 rounded mb-3">
                    <div class="row mb-3" v-for="index in aromaCounter" :key="index">
                      <div class="col-sm-6">
                        <label class="form-label">Aroma:</label>
                        <div class="d-flex gap-2">
                          <input type="text" class="form-control" v-model="aromaSpecificationValue[index - 1]" />
                          <button v-if="aromaCounter > 1"
                            @click="deleteSpecificationRow('aromaSpecificationArray', 'aromaSpecificationValue', 'aromaCounter', index - 1)"
                            type="button" class="btn btn-danger">X</button>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <button type="button" @click="addSpecification('aromaSpecificationValue', 'aromaCounter')"
                        class="btn btn-primary">Add More</button>
                    </div>
                  </div>

                  <!-- experience -->
                  <div class="border border-3 p-4 rounded mb-3">
                    <div class="row mb-3" v-for="index in experienceCounter" :key="index">
                      <div class="col-sm-6">
                        <label class="form-label">Experience:</label>
                        <div class="d-flex gap-2">
                          <input type="text" class="form-control" v-model="experienceSpecificationValue[index - 1]" />
                          <button v-if="experienceCounter > 1"
                            @click="deleteSpecificationRow('experienceSpecificationArray', 'experienceSpecificationValue', 'experienceCounter', index - 1)"
                            type="button" class="btn btn-danger">X</button>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <button type="button" @click="addSpecification('experienceSpecificationValue', 'experienceCounter')"
                        class="btn btn-primary">Add More</button>
                    </div>
                  </div>

                  <!-- flavour -->
                  <div class="border border-3 p-4 rounded mb-3">
                    <div class="row mb-3" v-for="index in flavourCounter" :key="index">
                      <div class="col-sm-6">
                        <label class="form-label">Flavour:</label>
                        <div class="d-flex gap-2">
                          <input type="text" class="form-control" v-model="flavourSpecificationValue[index - 1]" />
                          <button v-if="flavourCounter > 1"
                            @click="deleteSpecificationRow('flavourSpecificationArray', 'flavourSpecificationValue', 'flavourCounter', index - 1)"
                            type="button" class="btn btn-danger">X</button>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <button type="button" @click="addSpecification('flavourSpecificationValue', 'flavourCounter')"
                        class="btn btn-primary">Add More</button>
                    </div>
                  </div>

                  <!-- availableIn -->
                  <div class="border border-3 p-4 rounded mb-3">
                    <div class="row mb-3" v-for="index in availableInCounter" :key="index">
                      <div class="col-sm-6">
                        <label class="form-label">Available In:</label>
                        <div class="d-flex gap-2">
                          <input type="text" class="form-control" v-model="availableInSpecificationValue[index - 1]" />
                          <button v-if="availableInCounter > 1"
                            @click="deleteSpecificationRow('availableInSpecificationArray', 'availableInSpecificationValue', 'availableInCounter', index - 1)"
                            type="button" class="btn btn-danger">X</button>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <button type="button"
                        @click="addSpecification('availableInSpecificationValue', 'availableInCounter')"
                        class="btn btn-primary">Add More</button>
                    </div>
                  </div>


                  <div class="border border-3 p-4 rounded">
                    <div class="row g-3">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-sm-12">
                            <label for="inputCollection" class="form-label">Project:</label>
                            <select v-model="project" class="form-select form-control mb-3">
                              <option class="p-1" v-for="(row, index) in projectList" :key="index"
                                :value="{ id: row.id, name: row.title }">
                                {{ row.title }}
                              </option>
                            </select>
                          </div>
                          <div class="col-sm-12">
                            <label for="inputCollection" class="form-label">Strain:</label>
                            <select v-model="strain" class="form-select form-control mb-3">
                              <option class="p-1" v-for="(row, index) in strainList" :key="index"
                                :value="{ id: row.id, name: row.name }">
                                {{ row.name }}
                              </option>
                            </select>
                          </div>
                          <div class="col-sm-12">
                            <label for="inputCollection" class="form-label">Strain Type:</label>
                            <input type="text" class="form-control" id="inputProductTitle" v-model="strainType"
                              placeholder="Enter strain type" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="border border-3 p-4 rounded mt-3">
                    <div class="mb-3">
                      <label for="exampleInputEmail1">Is Newest Arrival: </label>
                      <div class="form-check form-switch mt-1">
                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked"
                          v-model="isNewestArrival" />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mt-3">
                    <div class="d-grid">
                      <button type="button" @click="addProduct" class="btn btn-primary">
                        Save Product
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!--end row-->
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src='../js/add-product.js'></script>

<style scoped>
@import "../css/add-product.css";
</style>