import { createRouter, createWebHistory } from "vue-router";
import guard from "../guard";
import Login from "../components/auth/template/Login";
import Dashboard from "../components/dashboard/template/Dashboard";
import BlogPost from "../components/Blog/BlogPost/template/BlogPost";
import AddBlogPost from "../components/Blog/BlogPost/template/AddBlogPost"
import UpdateBlogPost from "../components/Blog/BlogPost/template/UpdateBlogPost";
import Projects from "../components/project/template/Projects";
import AddProject from "../components/project/template/AddProject";
import UpdateProject from "../components/project/template/UpdateProject";
import Strains from "../components/strains/template/Strains";
import AddStrain from "../components/strains/template/AddStrain";
import UpdateStrain from "../components/strains/template/UpdateStrain";
//user
import Admin from "../components/user/template/Admin";


//Retailers
import Retailers from "../components/Retailers/Retailers/template/Retailers";
import AddRetailer from "../components/Retailers/Retailers/template/AddRetailer";
import UpdateRetailer from "../components/Retailers/Retailers/template/UpdateRetailer";

//CMS
import AboutUsSectionOneCMS from "../components/CMS/about-us/SectionOneCMS/template/SectionOneCMS";
import AboutUsSectionTwoCMS from "../components/CMS/about-us/SectionTwoCMS/template/SectionTwoCMS";
import AboutUsSectionThreeCMS from "../components/CMS/about-us/SectionThreeCMS/template/SectionThreeCMS";
import AboutUsSectionFourCMS from "../components/CMS/about-us/SectionFourCMS/template/SectionFourCMS";
import AboutUsSectionFiveCMS from "../components/CMS/about-us/SectionFiveCMS/template/SectionFiveCMS";
import AboutUsFAQSectionCMS from "../components/CMS/about-us/FAQSectionCMS/template/FAQSectionCMS";
import ContactUsCMS from "../components/CMS/contact-us/template/ContactUsCMS";
import PrivacyPageCMS from "../components/CMS/privacy-policy/template/PrivacyPageCMS";
import TermsPageCMS from "../components/CMS/terms-and-conditions/template/TermsPageCMS";
import HeaderFooterCMS from "../components/CMS/header-footer/HeaderFooterCMS/template/HeaderFooterCMS";
import NewsletterBannerCMS from "../components/CMS/newsletter-banner/template/NewsletterBannerCMS";
import HeroSectionSlider from "../components/CMS/home/hero-section/template/HeroSectionSlider";
import CareerListPageCMS from "../components/CMS/career/CareerListPageCMS/template/CareerListPageCMS";
import ApplyCareerModalCMS from "../components/CMS/career/ApplyCareerModalCMS/template/ApplyCareerModalCMS";
import AgeVerificationModalCMS from "../components/CMS/age-verification-modal/template/AgeVerificationModalCMS";
import StrainPageCMS from "../components/CMS/strain-page/template/StrainPageCMS";
import ProjectPageCMS from "../components/CMS/project-page/template/ProjectPageCMS";
import HeroSectionCMS from "../components/CMS/home/hero-section-cms/template/HeroSectionCMS";
import HomePageCMS from "../components/CMS/home/homePageCMS/template/HomePageCMS";
//Contact
import ContactMessageList from "../components/Contact/template/Contact";
import Newsletter from "../components/Newsletter/template/Newsletter";

//FAQ
import FAQ from "../components/CMS/faq/Faq/template/Faq";

//Social Links
import SocialLinks from "../components/CMS/social-links/template/SocialLinks";

// Career
import CareerApplicant from "../components/career-applicant/template/CareerApplicant";
import Career from "../components/career/template/Career";
import AddCareer from "../components/career/template/AddCareer";
import EditCareer from "../components/career/template/EditCareer";


//Product
import AddProduct from "../components/product/addProduct/template/AddProduct";
import UpdateProduct from "../components/product/updateProduct/template/UpdateProduct";
import ProductList from "../components/product/productList/template/Product";

const routes = [
    /* Auth Routes */
    { path: "/admin-login", name: "Login", component: Login },
    { path: "/", redirect: { name: 'Home' } },
    { path: "/home", name: "Home", component: Dashboard, beforeEnter: guard },

    //Blog
    { path: "/blog-post", name: "BlogPost", component: BlogPost, beforeEnter: guard },
    { path: "/add-blog-post", name: "AddBlogPost", component: AddBlogPost, beforeEnter: guard },
    { path: "/update-blog-post/:id", name: "UpdateBlogPost", component: UpdateBlogPost, beforeEnter: guard },

    //projects
    { path: "/projects", name: "Projects", component: Projects, beforeEnter: guard },
    { path: "/add-project", name: "AddProject", component: AddProject, beforeEnter: guard },
    { path: "/update-project/:id", name: "UpdateProject", component: UpdateProject, beforeEnter: guard },

    //strains
    { path: "/strains", name: "Strains", component: Strains, beforeEnter: guard },
    { path: "/add-strain", name: "AddStrain", component: AddStrain, beforeEnter: guard },
    { path: "/update-strain/:id", name: "UpdateStrain", component: UpdateStrain, beforeEnter: guard },

    //User
    { path: "/admins", name: "Admin", component: Admin, beforeEnter: guard },
    { path: "/add-retailer", name: "AddRetailer", component: AddRetailer, beforeEnter: guard },
    { path: "/update-retailer/:id", name: "UpdateRetailer", component: UpdateRetailer, beforeEnter: guard },
    //Retailers
    { path: "/retailers", name: "Retailers", component: Retailers, beforeEnter: guard },

    //CMS
    { path: "/about-us-section-one-cms", name: "AboutUsSectionOneCMS", component: AboutUsSectionOneCMS, beforeEnter: guard },
    { path: "/about-us-section-two-cms", name: "AboutUsSectionTwoCMS", component: AboutUsSectionTwoCMS, beforeEnter: guard },
    { path: "/about-us-section-three-cms", name: "AboutUsSectionThreeCMS", component: AboutUsSectionThreeCMS, beforeEnter: guard },
    { path: "/about-us-section-four-cms", name: "AboutUsSectionFourCMS", component: AboutUsSectionFourCMS, beforeEnter: guard },
    { path: "/about-us-section-five-cms", name: "AboutUsSectionFiveCMS", component: AboutUsSectionFiveCMS, beforeEnter: guard },
    { path: "/about-us-faq-section-cms", name: "AboutUsFAQSectionCMS", component: AboutUsFAQSectionCMS, beforeEnter: guard },
    { path: "/newsletter-banner-cms", name: "NewsletterBannerCMS", component: NewsletterBannerCMS, beforeEnter: guard },
    { path: "/contact-us-cms", name: "ContactUsCMS", component: ContactUsCMS, beforeEnter: guard },
    { path: "/privacy-page-cms", name: "PrivacyPageCMS", component: PrivacyPageCMS, beforeEnter: guard },
    { path: "/terms-page-cms", name: "TermsPageCMS", component: TermsPageCMS, beforeEnter: guard },
    { path: "/header-footer-cms", name: "HeaderFooterCMS", component: HeaderFooterCMS, beforeEnter: guard },
    { path: "/hero-section-slider", name: "HeroSectionSlider", component: HeroSectionSlider, beforeEnter: guard },
    { path: "/career-list-page-cms", name: "CareerListPageCMS", component: CareerListPageCMS, beforeEnter: guard },
    { path: "/apply-career-modal-cms", name: "ApplyCareerModalCMS", component: ApplyCareerModalCMS, beforeEnter: guard },
    { path: "/age-verification-modal-cms", name: "AgeVerificationModalCMS", component: AgeVerificationModalCMS, beforeEnter: guard },
    { path: "/strain-page-cms", name: "StrainPageCMS", component: StrainPageCMS, beforeEnter: guard },
    { path: "/project-page-cms", name: "ProjectPageCMS", component: ProjectPageCMS, beforeEnter: guard },
    { path: "/hero-section-cms", name: "HeroSectionCMS", component: HeroSectionCMS, beforeEnter: guard },
    { path: "/home-page-cms", name: "HomePageCMS", component: HomePageCMS, beforeEnter: guard },

    //Contact
    { path: "/contact", name: "ContactMessageList", component: ContactMessageList, beforeEnter: guard },
    { path: "/newsletter", name: "Newsletter", component: Newsletter, beforeEnter: guard },

    //FAQ
    { path: "/faq", name: "FAQ", component: FAQ, beforeEnter: guard },

    //Social Links
    { path: "/social-links", name: "SocialLinks", component: SocialLinks, beforeEnter: guard },

    /* Career Routes */
    { path: "/career-applicant", name: "CareerApplicant", component: CareerApplicant, beforeEnter: guard },
    { path: "/career", name: "Career", component: Career, beforeEnter: guard },
    { path: "/add-career", name: "AddCareer", component: AddCareer, beforeEnter: guard },
    { path: "/edit-career/:id", name: "EditCareer", component: EditCareer, beforeEnter: guard },

    //Product
    { path: "/add-product", name: "AddProduct", component: AddProduct, beforeEnter: guard },
    { path: "/update-product/:id", name: "UpdateProduct", component: UpdateProduct, beforeEnter: guard },
    { path: "/product-list", name: "ProductList", component: ProductList, beforeEnter: guard },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
