<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Project</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Update Project
              </li>
            </ol>
          </nav>
        </div>
        <div class="ms-auto"></div>
      </div>
      <hr />
      <form>
        <div class="card">
          <div class="card-body p-4">
            <h5 class="card-title">Update Project</h5>
            <hr />
            <div class="form-body mt-4">
              <div class="row">
                <div class="col-lg-8">
                  <div class="border border-3 p-4 rounded mb-3">
                    <div class="mb-3" v-if="uploadType === 'image'">
                      <label for="inputProductTitle" class="form-label">Heading</label>
                      <input type="text" class="form-control" id="inputProductTitle" placeholder="" v-model="heading" />
                    </div>
                    <div class="mb-3">
                      <label for="inputProductTitle" class="form-label">Title</label>
                      <input type="text" class="form-control" id="inputProductTitle" placeholder="Project title"
                        v-model="title" />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Description</label>
                      <ckeditor :editor="editor" v-model="description" :config="editorConfig"></ckeditor>
                    </div>
                  </div>
                  <div class="mb-3" v-if="uploadType === 'image'">
                      <label for="inputProductTitle" class="form-label">Button Text</label>
                      <input type="text" class="form-control" id="inputProductTitle" placeholder=""
                        v-model="buttonText" />
                    </div>
                  <div class="col-sm-12">
                    <label for="inputCollection" class="form-label">Upload Type:</label>
                    <select v-model="uploadType" class="form-select form-control mb-3">
                      <option class="p-1" value="image">
                        Image
                      </option>
                      <option class="p-1" value="video">
                        Video
                      </option>
                    </select>
                  </div>

                  <div class="border border-3 p-4 rounded" v-if="uploadType === 'image'">
                    <div class="mb-3">
                      <label class="form-label">Image: <strong>[Preferred Image Size: 1440X2114, Max Upload Limit:
                          1MB]</strong></label>
                      <input class="form-control mb-2" type="file" name="image" id="image" ref="image"
                        aria-describedby="imageHelp" accept="image/*" @change="uploadProjectImage" />
                      <img v-if="previewImage" :src="previewImage" height="80" />
                      <img v-else src="/core/assets/images/400.png" height="80" />
                    </div>
                  </div>

                  <div class="border border-3 p-4 rounded mb-4" v-if="uploadType === 'video'">
                    <label class="form-label">Video Thumbnail: <strong>[Preferred Image Size: 1440X2114, Max Upload Limit:
                        1MB]</strong></label>
                    <input class="form-control mb-2" type="file" name="image" id="image" ref="videoThumbnail"
                      aria-describedby="imageHelp" accept="image/*" @change="uploadVideoThumbnail" />
                    <img v-if="previewVideoThumbnail" :src="previewVideoThumbnail" height="80" />
                    <img v-else src="/core/assets/images/400.png" height="80" />

                    <br>

                    <label for="video" class="mt-3">Product Video:
                      <strong>[Max Upload Limit:
                        10MB]</strong></label>
                    <input class="form-control mt-3" type="file" ref="video" accept="video/*"
                      @change="uploadVideo('video', 'video', 'video', $event)" />
                    <div class="row mt-3">
                      <video width="320" height="240" v-if="preview.video" controls>
                        <source :src="preview.video" type="video/mp4">
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>

                  <div class="col-12 mt-3">
                    <div class="d-grid">
                      <button type="button" @click="updateProject" class="btn btn-primary">
                        Save Project
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!--end row-->
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
  
<script src='../js/update-project.js'></script>