<template>
    <div class="page-wrapper">
      <div class="page-content">
        <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div class="breadcrumb-title pe-3">Retailer</div>
          <div class="ps-3">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-0 p-0">
                <li class="breadcrumb-item">
                  <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Update Retailer
                </li>
              </ol>
            </nav>
          </div>
          <div class="ms-auto"></div>
        </div>
        <hr />
        <form>
          <div class="card">
            <div class="card-body p-4">
              <h5 class="card-title">Update Retailer</h5>
              <hr />
              <div class="form-body mt-4">
                <div class="form-body mt-4">
              <div class="row">
                <div class="col-lg-8">
                  <div class="border border-3 p-4 rounded mb-3">
                    <div class="mb-3">
                      <label for="inputProductTitle" class="form-label">Retailer Name:</label>
                      <input type="text" class="form-control" id="inputProductTitle" placeholder=""
                        v-model="singleRetailer.retailerName" />
                    </div>
                    <div class="mb-3">
                      <label for="inputProductTitle" class="form-label">Address</label>
                      <GMapAutocomplete 
                            @place_changed="setAddress" :value="singleRetailer.address"
                        :class="'form-control'"></GMapAutocomplete>
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Retailer Link:</label>
                      <input type="text" class="form-control" id="inputProductTitle" placeholder=""
                        v-model="singleRetailer.retailerLink" />
                    </div>
                    <div class="border border-3 p-4 rounded mb-3">
                      <div class="row mb-3" v-for="index in counter" :key="index">
                        <div class="col-sm-6">
                          <label class="form-label">Services:</label>
                          <div class="d-flex gap-2">
                            <input type="text" class="form-control" v-model="specificationValue[index - 1]" />
                            <button v-if="counter > 1" @click="deleteSpecificationRow(index - 1)" type="button"
                              class="btn btn-danger">X</button>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3">
                        <button type="button" @click="addSpecification()" class="btn btn-primary">Add More</button>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Is Active: </label>
                      <div class="form-check form-switch mt-1">
                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" v-model="singleRetailer.isActive" />
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="d-grid">
                      <button type="button" @click="updateRetailer" class="btn btn-primary">
                        Save Retailer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!--end row-->
            </div>
                <!--end row-->
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script src='../js/update-retailer.js'></script>