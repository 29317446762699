export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.home.homePageCMS,
            cmsData: {
                id: "",
                categorySectionTitle:"",
                categorySectionSubTitle:"",
                newArrivalSectionTitle:"",
                newArrivalSectionSubTitle:"",
                ourStrainSectionTitle:"",
                ourStrainSectionSubTitle:"",
                locationSectionTitle:"",
                locationSectionSubTitle:"",
                newsSectionTitle:"",
                newsSectionSubTitle:""
            }
        }
    },
    async created() {
        document.title = "Hyman Life - Home Page CMS";
        this.getData();
    },
    methods: {
        async getData() {
            try {
                let response = await this.getCmsData(this.url);
                if (response.status == 200) {
                    this.cmsData = (response.data.data.length > 0) ? response.data.data[0] : {};
                }
                else {
                    this.cmsData = {};
                }
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            }
        },
        async update() {
            try {
                // this.getCoordinates();
                let data = {
                    id: this.cmsData?.id,
                    categorySectionTitle: this.cmsData?.categorySectionTitle,
                    categorySectionSubTitle: this.cmsData?.categorySectionSubTitle,
                    newArrivalSectionTitle: this.cmsData?.newArrivalSectionTitle,
                    newArrivalSectionSubTitle: this.cmsData?.newArrivalSectionSubTitle,
                    ourStrainSectionTitle: this.cmsData?.ourStrainSectionTitle,
                    ourStrainSectionSubTitle: this.cmsData?.ourStrainSectionSubTitle,
                    locationSectionTitle : this.cmsData?.locationSectionTitle,
                    locationSectionSubTitle: this.cmsData?.locationSectionSubTitle,
                    newsSectionTitle : this.cmsData?.newsSectionTitle,
                    newsSectionSubTitle: this.cmsData?.newsSectionSubTitle
                };
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, data);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        },
        
    }
}